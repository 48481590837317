import { IntakeSummary } from '../components/Event';

function EventsIntakeRoute() {
  return <IntakeSummary />;
}

export const Component = EventsIntakeRoute;

export function clientLoader() {
  return null;
}
